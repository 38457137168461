.avatar {
  &--small {
    height: 3rem;
    width: 3rem;
    .avatar__image {
      width: 3rem;
      height: 3rem;  
    }
  }
  &--big {
    height: 6rem;
    width: 6rem;
    .avatar__image {
      width: 6rem;
      height: 6rem;  
    }
    .avatar__change {
      width: 6rem;
      height: calc(6rem / 2);
      border-radius: 0 0 calc(6rem / 2) calc(6rem / 2);
    
      bottom: 51px;
    }
  }
  &__image {
    border-radius: 50%;
    position: relative;
  }

  &__change {
    position: relative;
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: rgba($color-black, 0.4);
    color: rgba($color-white, 0.6);
    font-size: 9px;
    svg {
      margin: 6px 0 3px 0;
      fill: rgba($color-white, 0.6);
      width: 1rem;
    }
  }

  &__change.active {
    display: flex;
  }
  &__status.active {
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: $color-dark-mint;
    border-radius: 50%;
    position: relative;
    bottom: 1rem;
  }
}
