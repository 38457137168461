.page {
  height: 100%;

  &--scrollable {
    overflow-y: auto;
  }

  &__title {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }

  &__search {
    margin: 2rem 0;
  }

  &__sub-title {
    color: $color-lightish-blue;
    font-weight: bold;
  }
}
