/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 3.2rem;
    height: 1.5rem;

    /* The slider */
    &__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-very-light-blue;
      -webkit-transition: .4s;
      transition: .4s;

      &:before {
        position: absolute;
        content: "";
        height: 1rem;
        width: 1rem;
        left: 4px;
        bottom: 4px;
        background-color: $color-white;
        -webkit-transition: .4s;
        transition: .4s;
      }
    }

    /* Rounded sliders */
    &__round {
      border-radius: 34px;
        &:before {
          border-radius: 50%;
        }
    }

    /* Hide default HTML checkbox */
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    input:checked + &__slider {
      background-color: $color-dark-mint;
    }
        
    input:checked + &__slider:before {
      -webkit-transform: translateX(1.5rem);
      -ms-transform: translateX(1.5rem);
      transform: translateX(1.5rem);
    }
}
  

  
