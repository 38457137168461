.conversations-page {
  height: 100%;
  overflow-y: auto;

  h2 {
    margin-bottom: 1.5rem;
  }

  &__nts-button {
    color: white !important;

    @include respond-to("half-screen") {
      font-size: 0.7rem;
      text-align: center;
    }
  }

  &__content {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 30% 70%;

    @include respond-to("half-screen") {
      display: flex;
      flex-direction: column;
    }
  }

  &__triagist {
    @include respond-to("half-screen") {
      font-size: 0.8rem;
      width: 100%;
    }

    &--card {
      @extend %card, %card--light;
      padding: 2rem 1.5rem;
      margin-bottom: 1rem;
    }
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__conversation-list-container {
    height: 100%;
    position: relative;
  }

  &__conversations {
    position: absolute;
    left: 0;
    right: 0;

    @include respond-to("half-screen") {
      position: static;
      margin-top: 2.5rem;
    }
  }

  &__triagist-image {
    height: 4rem;
    width: auto;
    border-radius: 50%;
  }

  &__triagist-top-info {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &__info {
    font-size: 13px;

    &-title {
      color: $color-greyish;

      &--margin {
        margin-bottom: 0.5rem;
      }
    }

    &-text {
      color: $color-greyish-brown;
    }
  }

  &__info-box {
    display: grid;
    grid-template-columns: 50% 50%;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__actions {
    @include respond-to("half-screen") {
      display: flex;
      order: 3;
    }

    :first-child {
      margin-right: 1rem;
    }
  }
}
