.tooltip {
  position: absolute;
  max-width: 14rem;
  background-color: $color-white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
  border-radius: 4px;
  padding: 1rem;
  text-align: left;
  z-index: 10;

  &__hidden {
      display: none;
  }
}

.tooltip::before {
  position: absolute;
  top: -6px;
  left: 40px;
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 7px solid white;
  z-index: 12;
}

.tooltip::after {
  position: absolute;
  top: -8px;
  left: 38px;
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 9px solid rgba(0, 0, 0, 0.03);
  z-index: 11;
}