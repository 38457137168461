.icon-button {
  display: flex;
  align-items: center;
  padding: .8rem 1.7rem;
  background-color: $color-cornflower;
  border-radius: .2rem;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  transition: background-color .3s;
  
  &--textless {
    padding: .4rem 0 !important;
    font-size: 9px !important;
    text-align: center !important;
    width: 2rem;
    display: inline;

    .icon-button__icon {
      width: 0.9rem;
      margin-right: 0 !important;
    }
  }

  &--light {
    color: $color-white;
  }

  &--dark {

  }

  &:hover {
    background-color: $color-cornflower-dark;
  }

  &__icon {
    width: 1rem;
    margin-right: 1rem;
  }
}