.dashboard-page {
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .box {
    margin-top: 1.5rem;
  }

  &__month-select {
    margin-top: 1.5rem;

    .dropdown__title {
      display: none;
    }
  }

  &__center-employees {
    margin-top: 10px;
    border-spacing: 0;

    td {
      padding-top: 14px;
    }

    td:first-of-type {
      padding-right: 40px;
    }

    &__sub {
      padding-top: 10px;
      font-style: italic;
    }
  }

  &__center-admin {
    @include respond-to('half-screen') {
      order: 1;
    }
  }

  &__to-do {
    @include respond-to('half-screen') {
      order: 3;
    }
  }

  &__stats {
    @include respond-to('half-screen') {
      order: 2;
    }
  }

  &__center-progress {
    position: relative;
    padding-top: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    &__progress-bar {

      &__bar-overflow{
        position: relative;
        overflow: hidden;
        width: 180px; height: 90px;
        margin-bottom: -14px;

        h1 {
          margin: 2rem 0 0 0;
        }
      }

      &__bar{
        position: absolute;
        top: 0; left: 0;
        width: 180px; height: 180px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 5px solid $color-very-light-pink;
        border-bottom-color: $color-clear-blue;
        border-right-color: $color-clear-blue;
      }
    }

    &__stats {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 40px 0;
      height: 50px;
      width: 170px;

      &__item {
        text-align: left;
        &__circle {
          width: 8px;
          height: 8px;
          margin-top: 5px;
          border-radius: 50%;

          &--blue {
            background-color: $color-clear-blue;
          }

          &--grey {
            background-color: $color-very-light-pink;
          }
        }

        &__wrapper {
          display: flex;
          align-items: center;
          flex-direction: row;

          h3 {
            margin-left: 8px;
          }
        }
      }
    }
  }

  &__column {
    box-sizing: border-box;
    margin: 33px 33px 0 0;

    @include respond-to('half-screen') {
      margin: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(-n+3) {
      margin-top: 0;
    }

    &--small {
      width: calc(25% - 33px);
      min-width: 225px;

      @include respond-to('half-screen') {
        min-width: 0;
        width: 50%;
        padding: 0 1rem;
      }

    }

    &--wide {
      width: calc(50% - 33px);

      @include respond-to('half-screen') {
        font-size: .7rem;
        width: 100%;
      }
    }
  }

  &::after {
    content: '';
    flex: auto;
  }

  &__content {
    padding: 2rem 8rem 1rem;

    @include respond-to('half-screen') {
      padding: 2rem 2rem 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow: auto !important;
      height: 85vh;
      align-items:flex-start;
      margin-top: 0;
    }

    p {
      line-height: 1.5;
    }
  }

  &__employee-progress {
    max-height: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px;

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 10px 0 10px;
      align-items: center;

      & > div:first-child {
        width: 50%;
      }

      &__name {
        color: $color-black-two;
        font-size: 14px;
        font-weight: bold;
      }

      &__role {
        opacity: 0.4;
        font-size: 12px;
        font-weight: bold;
      }

      &__score {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 5px;

        &--light {
          opacity: 0.4;
        }
      }
    }

    &__border {
      width: calc(100% - 20px);
      margin: 10px;
      height: 2px;
      background-color: $color-very-light-pink;
    }
  }

  &__audits {
    padding: 20px;

    &__item {
      height: 120px;
      padding: 15px;
      opacity: 0.95;
      transition: all .5s;
      cursor: pointer;
      border-radius: 2px;
      background-color: $color-white;
      box-shadow: 0 2px 8px 0 $color-very-light-blue;

      display: flex;
      flex-direction: row;

      &__column {
        &--left {
          width: 100%;
        }

        &--right {
          width: 25%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p {
            flex-grow: 1;
          }
        }
      }

      &:not(:first-of-type) {
        margin-top: 20px;
      }

      &__table {
        border-spacing: 0;
        margin-top: 5px;

        th, td {
          text-align: left;
          padding: 5px 10px 5px 0;
        }

        td {
          padding-right: 35px;

          @media (max-width: 1650px) {
            padding-right: 10px;
          }
        }
      }

      &__arrow {
        transform: scale(-1, 1);
      }

      &:hover {
        transform: translateX(-0.3rem);
        transition: all .5s;
        box-shadow: 0 .6rem 1.5rem 0 $color-very-light-blue;
      }
    }

    &__show-everything {
      cursor: pointer;
      text-align: center;
      margin-top: 15px;
      color: $color-clear-blue;
    }
  }
}

.progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  margin-top: 1.5em;
  display: block;
  background-color: $color-very-light-pink;

  &__bar {
    background-color: $color-clear-blue;
    border-radius: 2px;
    height: 4px;
    width: 0;
  }
}
