.circle-loader {
  border: .3rem solid $color-light-blue-grey;
  border-radius: 50%;
  border-top: .3rem solid $color-clear-blue;
  width: 2.8rem;
  height: 2.8rem;
  animation: spin .8s linear infinite;

  &--center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
