.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;

  &--hidden {
    display: none;
  }

  &--active {
    z-index: $modal-z-index;
  }

  &--inactive {
    opacity: 0;
    transform: scale(0);
  }

  &--hidden &__card {
    animation-fill-mode: backwards;
  }

  &--open &__card {
    animation: scaleIn $modal-toggle-time;
  }

  &--closed &__card {
    animation: scaleOut $modal-toggle-time;
  }

  &--open &__overlay {
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
    background-color: rgba($color-greyish, .3);
  }

  &--closed &__overlay {
    backdrop-filter: blur(0rem);
    -webkit-backdrop-filter: blur(0rem);
  }

  &--flexible &__card-container, &--flexible &__card {
    width: auto;
    height: auto;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__card-container {
    width: 70%;
    height: 55%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__card {
    background-color: $color-white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 var(--very-light-blue);
    width: 100%;
    height: 100%;

    overflow-y: auto;
  }

  &__header {
    padding: 1.5rem 1.5rem .5rem 1.5rem;
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    padding: .5rem 4rem;
    //height: calc(100% - 3.3rem);
  }

  &__close {
    width: 1.3rem;
    cursor: pointer;
  }
}

.confirm-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;

  &--hidden {
    display: none;
  }

  &--active {
    z-index: $modal-z-index;
  }

  &--inactive {
    opacity: 0;
    transform: scale(0);
  }

  &--hidden &__card {
    animation-fill-mode: backwards;
  }

  &--open &__card {
    animation: scaleIn $modal-toggle-time;
  }

  &--closed &__card {
    animation: scaleOut $modal-toggle-time;
  }


  &--open &__overlay {
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
    background-color: rgba($color-greyish, .3);
  }

  &--closed &__overlay {
    backdrop-filter: blur(0rem);
    -webkit-backdrop-filter: blur(0rem);
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__card-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__card {
    background-color: $color-white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 var(--very-light-blue);
    width: 100%;
    height: 100%;
    padding: 1rem;

    overflow-y: auto;
  }

  &__header {
    padding: 1.5rem 1.5rem .5rem 1.5rem;
    display: flex;
    justify-content: center;
  }

  &__content {
    padding: .5rem 4rem;
    margin: .5rem;
    //height: calc(100% - 3.3rem);
  }

  &__controls {
    display: flex;
    right: 2rem;
    bottom: 1rem;
    justify-content: space-around;

    :first-child {
      margin-right: 1rem;
    }
  }
}
