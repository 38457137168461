.top-container {
  display: flex;
  justify-content: space-between;
}


.meeting-modal {

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding: 0 10px;
  }

  &__item {
    padding: 10px;
  }

  &__item__title {
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;

  }

  &__item__content {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}
