.centers-page {
  height: 100%;
  padding: 1.5rem 8rem 1rem 8rem;

  @include respond-to('half-screen') {
    padding: 2rem 2rem;
  }

  &__search {
    margin: 1.5rem 0;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
}
  

  
