.wave-point-comment-list {

  &__grid {
    @extend %grid-list__grid;

    grid-template-columns: 3% 5% 75% 15%;

    span:first-child {
      text-align: left;
    }
  }

  &__title {
    @extend %grid-list__title;
  }

  &__header {
    margin-bottom: 1rem;
  }
}