.input {
  display: inline-block;
  font-size: 12px;
  color: #333333;
  font-family: $base-font-family;
  width: 100%;

  &__header {
    background-color: white;
    //box-shadow: 0 1px 4px 0 #cfdeff;
    width: calc(100% - 1px);
    padding: .2rem;
  }

  &__label {
    width: 100%;
    margin-bottom: 1rem;
    color: $color-black;
    font-family: $base-font-family;
  }

  &__field {
    border-radius: 3px;
    background-color: rgba($color-very-light-blue, .15);
    border: 1px solid rgba($color-very-light-blue, .4);
    padding: .8rem .5rem;
    width: 100%;

    &--error {
      border-color: $color-tomato-red !important;
    }

    &--icon {
      padding: .8rem .5rem .8rem 3rem;
      position: relative;
    }

    &:focus {
      outline: none;
    }
  }

  &--icon {
    &__field-cont {
      position: relative;
      display: flex;
      align-content: center;
      flex-direction: row;

      &__icon {
        position: absolute;
        width: 1.5rem;
        height: 1.5em;
        transform: translate(0.7rem, 0.7rem);

        path {
          fill: $color-light-blue-grey;
        }
      }
    }
  }
}
