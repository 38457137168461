.sidebar-menu {
  top: 3.8rem;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: width .5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  cursor: col-resize;

  &--light {
    background-image: $color-purple-gradient;
    color: $color-white-60;
  }

  &--light &__icon {
    fill: $color-white-60;
  }

  &--dark {

  }

  &--open {
    width: $sidebar-menu-open-width;
  }

  &--closed {
    width: $sidebar-menu-closed-width;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: .4rem 1.3rem;
    margin: .5rem 0;
    position: relative;

    &--active span {
      color: $color-white;
    }

    &--active {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: .3rem;
        background-color: $color-white;
      }
    }
  }

  &__item--active &__icon {
    fill: $color-white;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 2rem;
  }

  &__label {
    white-space: nowrap;
  }
}
