.report-page {
  height: calc(100vh - 4.5rem);

  p {
    margin: 1rem;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}