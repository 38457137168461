.wave-point-comment {
  padding: .5rem 0;

  &__text {
    font-size: 0.75rem;

    &--bold {
      font-weight: bold;
    }
  }
}