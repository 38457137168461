.nav {
  position: sticky;
  z-index: 1000;

  &__title {
    font-weight: bolder;
  }

  &__center {
    font-size: 11px;
  }

  &--light {
    background-color: $color-white;
    color: $color-black;
  }

  &--dark {
    background-color: $color-black;
    color: $color-white;
  }

  &__logo {
    width: 5rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      &.active {
        color: #8b0000;
      }
    }
  }

  &__left {
    padding-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }
}
