.users-page {
    height: 100%;

    &__search {
        margin: 2rem 0;
        display: flex;
        justify-content: space-between;
    }

    &__hint {
        margin-bottom: 0.5rem;
        font-size: 0.8rem;
    }
}