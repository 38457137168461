.checkbox {
    display: inline;
    &__label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__box {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 1.5rem;
        width: 1.5rem;
        background-color: rgba($color-very-light-blue, .15);
        border: 1px solid rgba($color-very-light-blue, .4);
        border-radius: 2px;
    }

    &__box:checked ~ &__checkmark {
        background-color: $color-cornflower;
        border: none;
    }

    &__box--disabled:checked ~ &__checkmark {
        background-color: $color-light-blue-grey;
        border: none;
    }

    &__checkmark:after {
        content: "";
        position: absolute;
        display: none;

        left: 9px;
        top: 5px;
        width: 7px;
        height: 13px;
        border: solid $color-white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &__box:checked ~ &__checkmark:after {
        display: block;
    }
}
