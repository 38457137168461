.flex {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--justify-center {
    justify-content: center;
  }

  &--align-center {
    align-items: center;
  }

  &-1 {
    flex: 1;
  }

  &-2 {
    flex: 2;
  }

  &-gap {
    &--1 {
      gap: 1rem;
    }
  }
}
