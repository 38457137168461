.audit {
  margin: 0;

  @include box;

  @include respond-to('half-screen') {
    font-size: .6rem;
  }

  &:not(:last-child) {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &--approved {
    border-left: $color-greenish-teal 4px solid;
    margin-left: -4px;
  }

  &--denied {
    border-left: $color-coral 4px solid;
    margin-left: -4px;
  }

  &__triagist {
    display: flex;
    align-items: center;
  }

  &__img {
    height: 3rem;
    width: auto;
    border-radius: 50%;
    margin-right: 1rem;
  }

  &__progress-text {
    &--seperator {
      margin: 0 .2rem
    }

    &--bold {
      color: $color-black-two;
      font-weight: bold;
    }

    &--grey {
      color: $color-warm-grey;
    }
  }

  &__eye-icon {
    width: 20px;
    height: 20px;
  }
}
