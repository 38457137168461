.custom-alert {
    background: white;
    border: .5px solid lightgray;
    border-radius: 5px;
    padding: 2rem;

    p {
        margin-bottom: 1.5rem;
    }

    button {
        margin-right: 1rem;
    }
}