.question-modal {

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: .5rem;
    margin-bottom: 2rem;
  }

  &__inputs &__input {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__options {
    margin: 2rem 0;
    font-size: .8rem;
    font-weight: bold;

    :not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;

    :first-child {
      margin-right: 1rem;
    }
  }


}
