.audit-form {
  padding: 1.5rem 1rem;

  @include box;

  &:not(:last-child) {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &:hover {
    transform: translateX(-1rem);
  }

  &__text {
    text-align: left;
  }

  &__link {
    display: block;
    color: $color-clear-blue;
    letter-spacing: .86px;
    font-size: .75rem;

    &:first-child {
      margin-bottom: .5rem;
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: $color-clear-blue;
    }
  }
}
