.progress-icon {
    width: 20px;
    height: 20px;
    
    &--tick {
      path {
        fill: none;
        stroke: $color-dark-mint;
        stroke-width: 4;
        stroke-dashoffset: 23;
        stroke-dasharray: 23;
        stroke-linejoin: round;
        stroke-linecap: round;
        animation: draw 0.6s linear forwards;

        @keyframes draw {
          to {
            stroke-dashoffset: 0;
          }
        }
      }
    }

    &--cross {
      path {
        fill: none;
        stroke: $color-coral;
        stroke-width: 2;
      }
    }

    &--circle {
      height: 0px;

      svg {
        transform-origin: 10px 10px;
        transform: rotate(-90deg);
        overflow: hidden;
        width: 50px;
        height: 50px;

        circle {
          fill: none;
          stroke-width: 3;
          transform: translate(3px, 3px);
          stroke-dasharray: 60;
          stroke-linecap: round;
        }

        circle:nth-child(1) {
          stroke-dashoffset: 0;
          stroke: $color-very-light-pink;
        }

        circle:nth-child(2) {
          stroke: $color-clear-blue;
          animation: percent 1s linear;
          transition: stroke-dashoffset 0.8s ease;

          @keyframes percent {
            0% {
                stroke-dashoffset: 60; 
                stroke-width: 0;
            }
          }
        }
      }
    }
  }