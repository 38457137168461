.auth {
    background-color: $color-ice-blue-two;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4rem 0 1rem 0;

        &-text {
            margin-left: -1rem;

            &--big {
                font-size: 1.4rem;
                font-weight: 800;
            }

            &--small {
                font-size: 2rem;
            }
        }

        &-logo {
            height: 3rem;
        }
    }

    &__footer {
        font-size: .8rem;
        margin-top: 1rem;
        margin-bottom: 4rem;
    }

    &__page {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &__form {
            background-color: $color-white;
            display: flex;
            flex-direction: column;
            padding: 4rem 5rem;
            width: 600px;
            height: fit-content;

            &__otp-text {
                font-size: 18px;
                line-height: 1.67;
                font-weight: 300;
                max-width: 321px; 
            }

            a {
                color: $color-clear-blue;
            }

            .input {
                margin-top: 0.8rem;

                &__label {
                    font-size: 0.8rem;
                    margin-bottom: 0.8rem;
                }
            }

            &__back {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 1rem;
                align-self: flex-start;

                &:hover {
                    cursor: pointer;
                }

                svg {
                    width: 0.8rem;
                    height: auto;
                    margin-right: 1rem;

                    path {
                        fill: $color-light-blue-grey;
                    }
                }

                p {
                    color: $color-light-blue-grey;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-top: 2rem;

                &__button {
                    display: inline-block;
                    margin-top: 1rem;

                    &:first-child {
                        margin-right: 1rem;
                    }

                    &--cancel {
                        background-color: white;
                        color: $color-light-blue-grey;
                        border: 1px solid $color-light-blue-grey;

                        &:hover {
                            color: $color-white;
                            background-color: $color-light-blue-grey;
                        }
                    }
                }
            }

            &__sso-link {
                margin-top: 1rem;
                text-align: center;
            }

            &__error {
                padding-top: 1.2rem;
                color: $color-coral;
                font-size: 14px;
                letter-spacing: 1px;
                transition: opacity ease 0.5s;
        
                &--hidden {
                    opacity: 0;
                }

                &--success {
                    color: $color-dark-mint;
                }
            }

            &__divider {
                display: flex;
                justify-content: space-around;
                align-items: center;

                &__text {
                    padding: 0.5rem 1rem;
                }

                &__line {
                    height: 2px;
                    background-color: $color-ice-blue-two;
                    width: 100%;
                }
            }

            &__troubleshooting {
                flex-direction: row-reverse;
            }

            &__row {
                display: flex;
                width: 100%;
                margin-bottom: 1rem;

                &__meldkamer {
                    margin-bottom: 0.2rem;
                }
            }

            &__select-title {
                color: $color-black;
            }

            &__title {
                font-size: 2rem;
                margin: 0;
            }

            &__select-region {
                background-color: rgba($color-very-light-blue, 0.15);
                border: 1px solid rgba($color-very-light-blue, 0.4);
                width: 100%;
                height: 100%;
                padding: 1rem;

                &__option {
                    padding: 1rem;
                    box-shadow: none;
                }

                &:focus {
                    outline: none;
                }
            }

            &__checkbox {
                display: flex;
                align-items: center;

                &__text {
                    display: inline;
                    margin-left: 0.5rem;
                }

                &__box {
                    display: inline;
                }
            }

            &__grid {
                display: grid;
                gap: 1rem;
                grid-template-columns: 1fr;
                width: 100%;

                @media (min-width: 768px) {
                    grid-template-columns: 1fr 1fr;
                }

                &__item {
                    color: $color-clear-blue;

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        height: 100px;
                        width: auto;
                        max-width: 100px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    &__registar {
        &__progress {
            &__container {
                display: flex;
                flex-direction: row;
            }

            &__bar {
                width: 66px;
                height: 3px;
                margin: 28px 16px 25px 0px;
                background-color: $color-cornflower;
                opacity: 0.4;

                &--active {
                    opacity: 1;
                }
            }
        }

        &__form {
            &__qr {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 55%;
                    margin-bottom: 15px;
                }
                
                &__text {
                    margin-bottom: 10px;
                    text-align: center;
                }
            }

            &__otp {
                width: 60%;
                margin-top: 15px;
                margin-bottom: 15px;

                &__error {
                    color: $color-coral;
                    margin-top: 10px;
                }
            }
        }

        &__password-checks {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            margin-top: 15px;
            margin-bottom: 15px;

            &__check {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;

                span {
                    margin-left: 5px;
                }
            }
        }
    }
}
