.search-input {
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 1rem 3rem;
  font-size: 12px;

  &--light input, &--light input::placeholder {
    color: rgba(51, 51, 51, 0.5);
  }

  &--dark {

  }

  &-container {
    position: relative;
  }

  &__icon {
    position: absolute;
    left: .7rem;
    top: 56%;
    transform: translateY(-50%);
    font-size: 2rem;
    font-weight: 100;

    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }



  &--light &__icon {
    color: $color-very-light-blue;
  }

  &--dark &__icon {

  }
}