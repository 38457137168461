.criteria-box {
    margin-bottom: 1rem;
    width: 100%;
  
    &__content {
      margin-top: 1rem;
    }
  
    &--card {
      @extend %card;
      padding: 2rem 1.5rem;
      background-color: $color-white;
    }
    &__criteria {
  
      &-top {
        color: $color-greyish;
      }

      &-description {
          font-size: 0.8rem;
      }
    }
  }
  