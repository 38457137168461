.add-button {
  display: inline-flex;
  align-items: center;
  color: $color-clear-blue;
  cursor: pointer;
  user-select: none;

  &__container {
    display: flex;
    justify-content: center;
    padding: .5rem;
  }

  &:hover {
    color: darken($color-clear-blue, 20%);
  }

  &__icon {
    font-size: 1.4rem;
    margin-right: .5rem;
  }

  &__text {
    letter-spacing: 0.86px;
    font-size: .725rem;
  }
}
