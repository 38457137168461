:root {
	--toastify-color-success: #2BDA7E;
	--toastify-color-error: #D71E00;
}

.Toastify {
	&__toast {
		&--success {
			border-left: 0.4rem solid $color-dark-mint;
		}

		&--error {
			border-left: 0.4rem solid $color-tomato-red;
		}
	}
}