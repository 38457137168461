@import 'reset';
@import 'typography';

html {
  height: 100%;
}

body {
  background: $background-color no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 100%;
}

main, #root {
  height: 100%;
}

input[type=text]:disabled, input[type=password]:disabled, textarea:disabled {
  cursor: not-allowed;
}

.title {
  font-size: 2rem;
}

.edit-text {
  display: block;
  color: $color-clear-blue;
  letter-spacing: .86px;
  font-size: .75rem;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: .5rem;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: $color-clear-blue;
  }
}
