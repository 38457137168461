.loader-page {
  display: flex;
  align-items: center;
  justify-content: center;

  &__logo {
    width: 50vw;
    height: 50vh;
    animation: scaleInOut 2s linear infinite;
  }
}
