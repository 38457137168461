.audio-player {
  position: fixed;
  left: $sidebar-menu-open-width;
  right: 0;
  bottom: 0;
  user-select: none;
  z-index: $audio-player-z-index;
  outline: none;
  transition: left $modal-toggle-time;

  &__modal {
    @include respond-to($screen-size-half) {
      .modal__card-container {
        width: 98%;
        height: 60%;
      }

      .modal__content {
        padding: .5rem 1rem;
      }
    }
  }

  &--big {
    left: $sidebar-menu-closed-width;
  }

  &--fullscreen {
    left: 0;
  }

  &--fullscreen &__controls {
    padding-left: calc(5rem);
  }

  &__play-speed {
    margin-left: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  &__info-icon {
    height: 2rem;
    width: auto;
    stroke: $color-bluey-grey;
    margin-right: .5rem;
    margin-left: -.4rem;
  }

  &__hint-text {
    font-size: 1rem;
    color: $color-bluey-grey;
  }

  &__cancel-button {
    margin-right: 1rem;
  }

  &__container {
    &:not(:last-child) {
      margin-right: 3rem;
    }

    &:first-child {
      margin-right: 1.5rem;
    }

    @include respond-to($screen-size-half) {
      &:not(:last-child) {
        margin-right: 0;
      }

      &:first-child {
        margin-right: 0;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.5rem 3rem;
    background-color: $color-ice-blue-four;
    box-shadow: 0 -2px 4px 0 rgba($color-very-light-blue, .5);
    transition: padding-left $modal-toggle-time;

    @include respond-to($screen-size-half) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__play-controls {
    display: flex;
    justify-content: space-around;
  }

  &__control {
    cursor: pointer;

    &--space-around {
      margin: 0 1rem;
    }

    &--text {
      display: flex;
      flex-direction: column;
    }
  }

  &__control-icon {
    width: 1rem;
    height: auto;

    &--toggle {
      margin: 0 .5rem;
    }
  }

  &__text-control {
    &-text {
      font-size: 10px;
      color: $color-black-two;
      opacity: .9;

      &--absolute {
        position: absolute;
        bottom: 1rem;
      }
    }
  }

  &__bar-container {
    cursor: pointer;
    border-radius: 10rem;
    height: .35rem;
    background-color: rgba($color-greyish-two, .25);
  }

  &__bar {
    border-radius: 10rem;
    height: 100%;
    transition: width .2s;
  }

  &__progress-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 2;

    @include respond-to($screen-size-half) {
      flex: unset;
      order: -1;
      width: 100%;
      margin: 0 0 1rem 0;
    }
  }

  &__progress {
    @extend .audio-player__bar-container;

    flex: 1;
    margin: 0 1rem;
    width: 50%;
    position: relative;
  }

  &__progress-bar {
    @extend .audio-player__bar;
    transition: none;

    background-color: $color-clear-blue;
  }

  &__duration-text {
    font-size: .75rem;

    &--blue {
      color: $color-clear-blue;
      font-weight: bold;
    }
  }

  &__volume-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__volume {
    @extend .audio-player__bar-container;

    width: 5.5rem;
    margin: 0 .5rem;
  }

  &__volume-bar {
    @extend .audio-player__bar;

    background-color: $color-black;
  }

  &__volume-icon {
    width: 1rem;
    height: auto;
    cursor: pointer;

    &--big {
      width: 1.2rem;
    }
  }

  &__file {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &--main {
      font-size: .85rem;
      opacity: .9;
      font-weight: bold;
      color: $color-black-two;
      margin-bottom: .2rem;
    }

    &--secondary {
      opacity: 0.3;
      font-size: .7rem;
    }
  }

  &__resize {
    display: flex;
    align-items: center;
  }

  &__resize-icon {
     width: 1.5rem;
    cursor: pointer;
  }

  &__wave {
    margin: 2rem 0 2.5rem 0;
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 100%;
  }

  &__wave-time {
    position: absolute;
    bottom: -1px;
    font-size: 12px;
    background-color: $color-white;
    font-family: OpenSans, sans-serif;

    &--left {
      padding: 4px 8px 0 0;
      left: 0;
    }

    &--right {
      padding: 4px 0 0 8px;
      right: 0;
    }
  }

  &__audio-bar {
    width: 6px;
    background-color: $color-butterscotch;
    cursor: pointer;

    &:hover {
      opacity: .26;
    }

    &:not(:last-child) {
      margin-right: 2px;
    }
  }

  &__wave-indicator {
    position: absolute;
    top: 0;
    bottom: -.5rem;
    left: 0;
    width: .1rem;
    background-color: $color-clear-blue;
    z-index: 1;
  }

  &__wave-point {
    position: absolute;
    background-color: $color-tomato-red;
    color: $color-white;
    font-size: .5rem;
    user-select: none;

    &--bar {
      top: -5px;
      bottom: 0;
      width: 2px;
    }

    &--wave {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1rem;
      height: 1rem;
      bottom: -1.5rem;
      border-radius: 10rem;
      cursor: pointer;
    }

    &--transparent {
      opacity: .5;
    }
  }

  &__wave-point-input {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    span {
      font-size: .75rem;
      font-weight: bold;

      &:first-child {
        margin-right: 1rem;
      }
    }

    .input {
      flex: 1;
      margin: 0 1rem 0 2rem;
    }
  }

  &__icon {
    width: 1.2rem;
    height: auto;

    &--big {
      width: 1.5rem;
    }

    &--space-between {
      margin: 0 1rem;
    }
  }

  &__date {
    span:first-child {
      font-size: 0.8rem;
      color: $color-greyish;
      margin-right: 2rem;
    }

    span:last-child {
      font-size: .87rem;
      color: $color-greyish-brown;
    }
  }
}
