.tab-navigation {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__bar {
    padding: 1.5rem 8rem 0 8rem;
    box-shadow: 0 10px 12px -6px rgba(178, 193, 227, 0.27);
    list-style: none;

    @include respond-to('half-screen') {
      padding: 1.5rem 2rem 0 2rem;
    }

    &--light {
      background-color: $color-ice-blue;
    }

    &--dark {

    }

    &--bottom {
      display: flex;
      align-items: center;
    }
  }

  &__tab {
    font-size: 14px;
    font-weight: bold;
    position: relative;
    padding: .5rem .5rem .5rem 0rem;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    &--active:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
    }
  }

  &--light &__tab {
    color: rgba($color-black, .5);

    &--active {
      color: $color-clear-blue;

      &:after {
        background-color: $color-clear-blue;
      }
    }
  }

  &__content {
    padding: 1.5rem 8rem 2rem 8rem;

    @include respond-to('half-screen') {
      padding: 1.5rem 2rem 2rem 2rem;
    }

    &--scrollable {
      overflow: auto;
    }
  }

  &__breadcrumbs {
    font-size: 13px;
    color: rgba($color-warm-grey, .65) !important;

    &--active {
      color: $color-warm-grey !important;
    }
  }
}
