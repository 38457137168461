.default-input {
  &--big {
    min-width: 24rem;
  }

  &--full {
    width: 100%;
  }

  &--focused &__header {
    color: $color-clear-blue;
  }

  &--error &__field {
    border: 1px solid $color-tomato-red !important;
  }

  &__error {
    color: $color-tomato-red;
  }

  &__main {
    position: relative;
  }

  &__field {
    &--password, &--icon {
      padding: .8rem 2rem;
    }
  }

  &--big &__field:focus {
    border: 1px solid rgba($color-cornflower, .5);
  }

  &--small &__field:focus {
    border: 1px solid $color-clear-blue;
  }

  &__icon {
    width: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--left {
      left: .5rem;
    }

    &--right {
      right: .5rem;
    }
  }
}
