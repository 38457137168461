.edit-password {

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    color: rgba($color-black, .96);
  }

  &__form {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;

    :not(:last-child) {
      margin-bottom: .5rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;

    :first-child {
      margin-right: 1rem;
    }
  }
}