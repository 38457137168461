.modal__card-container.user-modal {
  width: 50%;
  height: 75%;
  top: 60%;
}

.user-modal {
  align-items: center;
  padding-bottom: 1rem;

  .modal__card-container {
    height: 80%;
  }

  &__title {
    width: auto;
    align-self: flex-start;
    margin-bottom: 2rem;
  }

  .avatar {
    margin-bottom: 1.5rem;
  }

  &__link {
    cursor: pointer;
    color: $color-clear-blue;
  }

  .input {
    margin-bottom: 1rem;
    &__header {
      box-shadow: none;
    }
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 24rem;
    font-size: 12px;
  }

  &__label {
    width: auto;
    align-self: flex-start;
    margin-bottom: 1rem;
  }

  &__error {
    color: $color-coral;
    font-size: 14px;
    letter-spacing: 1px;
    transition: opacity ease 0.5s;
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    position: relative;

    .checkbox {
      &__label {
        width: 170px;
      }
    }

    .auditor-tooltip {
      top: 2rem;
    }
    .admin-tooltip {
      top: 2rem;
      left: 10.5rem;
    }
  }

  &__button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;

    button {
      margin-right: 1rem;
    }
  }
}
