.upload-box {
  border-radius: .31rem;
  box-shadow: 0 .25rem .5rem 0 $color-very-light-blue;
  padding: 1.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__direction-icon {
    width: 1.5rem;
    fill: $color-greyish-brown;
    cursor: pointer;

    &--previous {
      &:only-child {
        margin-right: 2.5rem;
      }
    }

    &--next {
      transform: rotateY(180deg);
      margin-left: 1rem;
    }
  }

  &__check-icon {
    width: 100%;
    height: 100%;
  }

  &__editor {
    border: .5px solid $color-very-light-blue;
    padding: 0 .5rem;
    max-height: 100%;
    overflow: scroll;
  }

  &__editor-toolbar {
    //display: none !important;
  }

  &__title {
    font-weight: bold;
    font-size: .875rem;
  }

  &__content {
    padding: 1rem 0;
  }

  &__allowed-files-text {
    display: inline-block;
    color: rgba($color-black, .5);
    font-size: .725rem;
    letter-spacing: .86px;
    margin-bottom: 1rem;
  }

  &__files {
    margin-top: 1rem;
  }

  &__file-title {
    display: inline-block;
    font-weight: bold;
    font-size: .75rem;
    color: rgba($color-greyish, .8);
    text-transform: uppercase;
    margin-bottom: .8rem;
  }

  &__actions {
    margin-top: 2rem;
    text-align: right;
  }

  &__no-files-text {
    display: block;
    font-size: .875rem;
  }

  &__upload-container {
    margin-bottom: 1rem;
  }
}
