.form-question {
  display: flex;
  align-items: center;
  padding: 1.5rem $padding-box;
  font-size: 0.875rem;
  color: $color-black;
  transition: 0.8s transform;

  &__container {
    background-color: $color-ice-blue-four;

    &:not(:last-child) {
      border-bottom: 1px solid rgba($color-black, 0.1);
    }

    &--dragging {
      border: 3px solid darken($color-ice-blue-four, 15%) !important;
      background-color: darken($color-ice-blue-four, 10%);
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba($color-black, 0.1);
  }

  &--open {
    background-color: $color-white;
    transform: translateX(-0.5rem);
    border-radius: 3px;

    box-shadow: 0 5px 8px rgba($color-very-light-blue, 0.3);
  }

  &--knockout {
    border-left: 2px solid $color-tomato-red;
  }

  &--necessary {
    border-left: 2px solid $color-butterscotch;
  }

  &--desirable {
    border-left: 2px solid $color-greenish-teal;
  }

  &__input {
    padding: 0.5rem;
    margin: 0 2rem 0 1rem;
  }

  &__back {
    margin-right: 1.5rem;
  }

  &__question {
    flex: 1;
  }

  &__radio {
    margin-right: 0.5rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    border: 1px solid $color-bluey-grey;
    transition: 0.1s all linear;
    outline: none;
    position: relative;
    cursor: pointer;

    &:checked {
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 75%;
        height: 75%;
        transform: translate(-50%, -50%);
        border-radius: 100rem;
      }
    }

    &--green {
      &:checked {
        border-color: $color-dark-mint;
        box-shadow: 0 0 6px 0 rgba($color-dark-mint, 0.75);

        &:after {
          background-color: $color-dark-mint;
        }
      }
    }

    &--red {
      &:checked {
        border-color: rgba($color-tomato-red, 0.75);
        box-shadow: 0 0 6px 0 rgba($color-tomato-red, 0.75);

        &:after {
          background-color: $color-tomato-red;
        }
      }
    }

    &--orange {
      &:checked {
        border-color: $color-maize;
        box-shadow: 0 0 6px 0 rgba($color-maize, 0.75);

        &:after {
          background-color: $color-maize;
        }
      }
    }
  }

  &__answers {
    display: flex;
    align-items: center;
  }

  &__answer-label {
    margin-right: 1.5rem;
  }

  &__icon {
    width: 1.7rem;
    cursor: pointer;
  }

  &__add-comment {
    font-size: 0.75rem;
    letter-spacing: 0.86px;
    color: $color-clear-blue;
    max-width: 12%;
    cursor: pointer;
  }

  &__info-container {
    position: relative;
    margin-right: 3rem;
  }

  &__explanation-box {
    display: none;
    position: absolute;
    padding: 1rem 2rem;
    left: 4rem;
    top: 50%;
    background-color: $color-cornflower;
    box-shadow: -0.5rem 0.2rem 0.8rem rgba(0, 0, 0, 0.2);
    border-radius: 0.1rem;
    width: 40rem;
    color: $color-white;
    font-weight: bold;
    z-index: 1;
    transform: translateY(-50%);

    @include respond-to("half-screen") {
      width: 20rem;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 2rem;
      height: 3rem;
      background-color: inherit;
      border-radius: 0.1rem;
      transform: translateX(-80%) translateY(-50%);
      clip-path: polygon(0 50%, 100% 0, 100% 100%);
    }
  }

  &__info-container:hover &__explanation-box {
    display: block;
  }
}
