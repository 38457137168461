.dropdown {
  cursor: pointer;
  letter-spacing: .86px;
  font-size: .75rem;
  user-select: none;

  &--disabled {
    cursor: not-allowed;
  }

  &__search-bar {
    padding: .7rem;
    background-color: rgba($color-very-light-blue, 0.15);
    position: sticky;
  }

  &__clear:hover {
    color: $color-clear-blue;
  }

  &__title {
    margin-bottom: .5rem;
  }

  &__custom-select {
    width: 100%;
    position: relative;
    background-color: $color-white;
  }

  &__options {
    background-color: #ffffff;
    border: 1px solid lighten($color-very-light-blue, 0.4);
    border-top: none;
    display: none;
    position: absolute;
    width: 100%;

    &--open {
      display: block;
      z-index: $dropdown-z-index;
    }
  }

  &__scroll-area {
    max-height: 12rem;
    overflow: auto;
  }

  &__option {
    padding: .7rem;
    background-color: rgba($color-very-light-blue, 0.15);

    &:hover {
      background-color: rgba($color-very-light-blue, 0.3);
    }
  }

  &__selected {
    background-color: rgba($color-very-light-blue, 0.15);
    border: 1px solid rgba($color-very-light-blue, 0.4);
    padding: .7rem;
    position: relative;

    &--placeholder {
      &__text {
        color: $dark-grey;
      }
    }
  }

  &__arrow {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 0.3rem solid transparent;
    border-right: 0.3rem solid transparent;
    border-top: 0.4rem solid #888888;

    &--open {
      transform: rotate(180deg) translateY(50%);
    }
  }

  &__selected-text {
    display: inline;
  }
}
