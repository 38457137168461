.item {
  @include box;

  &:not(:last-child) {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &--uploaded {
    padding: 1rem 0;
    position: relative;

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 10%;
        right: 0;
        height: 2px;
        background-color: rgba($color-black, .1);
      }
    }
  }

  &:hover {
    transform: translateX(-1rem);
  }

  &__text {
    font-weight: bold;
  }

  &__link {
    cursor: pointer;
    margin-bottom: .5rem;
    padding-left: 10px;
    color: $color-clear-blue;
  }
}