.nav-notifications {
  position: relative;

  &--light {

  }

  &--dark {

  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    height: 100%;
  }

  &__bell {
    width: 2.2rem;
    cursor: pointer;
  }

  &__list {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $color-white;
    box-shadow: 2px 2px 4px 0 #cfdeff;
    font-size: .8rem;
    min-width: 18rem;
    border-radius: 2px;
    z-index: $message-box-z-index;

    &--open {
      display: block;
    }

    &--closed {
      display: none;
    }
  }

  &__list-header {
    display: flex;
    justify-content: space-between;
  }

  &__notification {
    &:not(:last-child) {
      border-bottom: .5px solid $color-clear-blue-20;
    }

    &-content {
      padding: .5rem 1rem;
    }
  }

  &__title {
    font-weight: bold;
  }

  &--light &__action {
    color: $color-clear-blue;
    letter-spacing: 0.71px;
    cursor: pointer;
  }

  &__message, &__hour {
    display: block;
  }

  &__hour {
    text-align: right;
    color: $color-light-blue-grey;
  }
}