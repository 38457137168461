.box-shortcut {
  display: flex;
  justify-content: space-between;
  padding: 1rem $padding-box;
  position: relative;
  cursor: pointer;

  &--active &__text--top {
    color: $color-clear-blue;
  }

  &:not(:last-child) {
    &:after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: .5rem;
      opacity: .1;
      background-color: $color-black;
    }
  }

  &__content {
    &--left {
      display: flex;
      flex-direction: column;

      span:first-child {
        font-weight: bold;
      }
    }

    &--right {
      display: flex;
      align-items: center;
    }
  }

  &__text {
    &--top {
      font-weight: bold;
      font-size: .75rem;
      margin-bottom: .2rem;
    }

    &--bottom {
      opacity: .3;
      color: $color-black;
      font-size: .7rem;
      font-weight: bold;
    }
  }

  &__arrow {
    transform: rotate(180deg);
    width: .8rem;
  }
}