.results-box {
  width: 100%;

  &__content {
    margin-top: 1rem;
  }

  &--card {
    @extend %card;
    padding: 2rem 1.5rem;
    background-color: $color-white;
  }
  &__score {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 10% 45% 45%;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.2rem;
    }

    &-top {
      color: $color-greyish;
    }

    &-block {
      height: 1.5rem;
      width: 20%;
      &--desirable {
        background-color: $color-greenish-teal;
      }

      &--necessary {
        background-color: $color-butterscotch;
      }

      &--knockout {
        background-color: $color-tomato-red;
      }
    }
  }
}
