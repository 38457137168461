.criteria {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 10% 90%;
    align-items: center;
    margin-bottom: 1rem;
    
    &__block {
        height: 2.5rem;
        width: 20%;

        &--desirable {
          background-color: $color-greenish-teal;
        }
  
        &--error_repeat {
          background-color: $color-butterscotch;
        }
  
        &--knockout {
          background-color: $color-tomato-red;
        }
    }
}