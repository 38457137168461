.pagination {
  width: max-content;

  span {
    display: inline-block;
    background-color: $color-white;
    margin: 0.7rem 0.7rem 0.7rem 0px;
    padding: 0.7rem;

    color: $color-bluey-grey-two;
    font-size: 12px;
    user-select: none;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__button {
    cursor: pointer;
  }
}