%grid-list {

  &__grid {
    display: grid;
    grid-column-gap: .8rem;

    span:first-child {
      text-align: center;

      @include respond-to('half-screen') {
        text-align: left;
        padding-left: .5rem;
      }
    }
  }

  &__title {
    color: $color-bluey-grey-two;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;

    @include respond-to('half-screen') {
      font-size: .5rem;
    }
  }

  &--child-has-animations {
    margin-left: -30px;
    padding-left: 30px;
  }
}
