.center-detail-page {
  padding-bottom: 2rem;

  &__header {
    padding-bottom: 1rem;
    align-items: flex-end;
  }

  &__title {
    color: $color-lightish-blue;
    margin-top: 1rem;
    font-weight: bold;
  }

  &__card {
    @extend %card;

    background: $color-white;
    padding: .5rem 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__link {
    color: $color-clear-blue !important;
    font-size: 0.75rem;
    letter-spacing: .86px;
    text-align: right;
    margin-top: auto;
    margin-bottom: 1rem;
    display: block;
  }

  &__role {
    font-size: .875rem;
    margin-bottom: .5rem;

    display: flex;
    justify-content: space-between;
  }
}
