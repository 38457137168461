.center {
  margin: 0 !important;
  padding: 1rem;
  border-bottom: 1px solid #eeeeee;
  cursor: default;

  @include box;

  &--with-animations {
    cursor: pointer;
  }

  &__name {
    font-weight: bold;
    text-align: left !important;
  }

  &--without-animations &:hover {
    transform: none;
  }

  &:hover {
    box-shadow: none;
  }

}
