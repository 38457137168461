.conversation-form {
  display: flex;
  height: 100%;
  outline: none;

  &__comment {
    margin: 1.5rem 0;
    box-shadow: 0 2px 4px 0 $color-very-light-blue;
    border-radius: 0.1rem;
    background-color: $color-white;

    textarea {
      resize: vertical;
      vertical-align: top;
      font-size: 14px;
    }

    span {
      font-weight: bold;
      color: $color-black;
      padding: 0.3rem;
      display: inline-block;
    }
  }

  &__back-button {
    margin-right: 1.5rem;
  }

  &__table {
    .table__header, .table__row {
      grid-template-columns: 8% 12% 22% 53%;
      color: $color-black;
    }

    .table__row {
      span:nth-child(1), span:nth-child(2) {
        opacity: .6;
      }
    }
  }

  &__content {
    &--left {
      flex: 1;
      margin-right: 1rem;
      padding-right: 1rem;
      padding-bottom: 4rem;
      max-height: 75vh;
      overflow-y: scroll;
    }

    &--right {
      flex: 3;
      padding-right: 0.7rem;
      overflow-y: auto;
    }
  }

  &__scroll-content {
    max-height: 75vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    outline: none;

    margin: 0 -.7rem;
    padding: 0 .7rem 4rem .7rem;

    &--solid {
      overflow-y: unset;
    }
  }

  &__info {
    padding: 1rem $padding-box;
  }

  &__info-block {
    font-size: .875rem;
    display: grid;
    grid-template-columns: 25% 75%;
    margin-bottom: 1rem;

    span:first-child {
      opacity: .6;
      color: $color-black;
    }
  }

  &__spaced-block {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: space-between;
  }

  &__finish-button {
    margin: 1rem 0;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3rem;

    button:last-child {
      margin-left: 3rem;
    }
  }
}

.conversation-box {
  overflow-y: hidden;
  overflow-x: hidden;
}
