$base-font-size: 16px;

body {
  font-size: $base-font-size;
  font-family: $base-font-family;
  color: $text-color;
}

.font-lato {
  font-family: 'Lato', 'sans-serif';
}

.font-opensans {
  font-family: 'OpenSans', 'sans-serif';
}

.title--white {
  @extend .font-lato;

  font-size: 72px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
.title--blue {
  @extend .font-lato;

  font-size: 72px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--clear-blue);
}
.title--black {
  @extend .font-lato;

  font-size: 72px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}
.heading1--white {
  @extend .font-lato;
  font-size: 48px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}

.heading1--blue {
  @extend .font-lato;
  font-size: 48px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--clear-blue);
}

.heading1--black {
  @extend .font-lato;
  font-size: 48px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.heading2--white {
  @extend .font-lato;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
.heading2--blue {
  @extend .font-lato;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--clear-blue);
}
.heading2--black {
  @extend .font-lato;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}
.heading3--white {
  @extend .font-lato;
  font-size: 23px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
.heading3--blue {
  @extend .font-lato;

  font-size: 23px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--clear-blue);
}
.heading3--black {
  @extend .font-lato;

  font-size: 23px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}
.heading4--white {
  @extend .font-lato;

  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
.heading4--blue {
  @extend .font-lato;

  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--clear-blue);
}
.heading4--black {
  @extend .font-lato;

  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}
.system-lead--white {
  @extend .font-lato;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: $color-white;
}
.button2--white {
  @extend .font-lato;

  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  text-align: center;
  color: $color-white;
}
.button2--blue {
  @extend .font-lato;

  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  text-align: center;
  color: var(--clear-blue);
}
.button2--black {
  @extend .font-lato;

  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  text-align: center;
  color: #969696;
}
.system-lead--blue {
  @extend .font-lato;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--clear-blue);
}
.system-lead--black {
  @extend .font-lato;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #333333;
}
.heading5--white {
  @extend .font-lato;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-white;
}
.heading5--tonal {
  @extend .font-lato;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(51, 51, 51, 0.5);
}
.heading5--blue {
  @extend .font-lato;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--clear-blue);
}
.heading5--black {
  @extend .font-lato;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}
.systembase--white {
  @extend .font-lato;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--white);
}
.systembase--blue {
  @extend .font-lato;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $color-clear-blue;
}
.systembase--black {
  @extend .font-lato;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}
.systemeye--browwhite {
  @extend .font-lato;

  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: var(--white);
}
.systemeye--browblue {
  @extend .font-lato;

  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #2c16c7;
}
.systemeye--browblack {
  @extend .font-lato;

  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  color: #aab2c0;
}
.button--white {
  @extend .font-opensans;

  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: var(--white);
}
.button--black {
  @extend .font-opensans;

  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  color: #aab2c0;
}
.button--blue {
  @extend .font-opensans;

  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  color: var(--clear-blue);
}
.smalltekst--white-undercase {
  @extend .font-lato;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  color: var(--white);
}
.smalltekst--tonal-undercase {
  @extend .font-lato;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  color: rgba(51, 51, 51, 0.5);
}
.smalltekst--blue-undercase {
  @extend .font-lato;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  color: var(--clear-blue);
}
.smalltekst--black-undercase {
  @extend .font-lato;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  color: #333333;
}
