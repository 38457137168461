.observer-list {
  overflow-y: auto;
  @extend %grid-list--child-has-animations;

  &__grid {
    &--events {
      @extend %grid-list__grid;
      padding: 1rem;
      grid-template-columns: 5% 20% 20% 5% 10% 10% 10% 10%;
    }

    &--logins {
      @extend %grid-list__grid;
      padding: 1rem;
      grid-template-columns: 5% 5% 25% 15% 10% 10% 10% 10%;
    }

    &--feedback {
      @extend %grid-list__grid;
      padding: 1rem;
      grid-template-columns: 5% 15% 25% 10% 20% 10%;
    }
  }

  &__header {
    background: $color-white;
 }

  &__title {
    @extend %grid-list__title;
    text-align: left !important;
  }

  &__login-attempts {
    max-height: 73%;
  }
}
