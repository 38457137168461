.audit-form-list {
  @extend %grid-list--child-has-animations;

  height: 100%;

  &__header {
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 1rem;
    overflow: hidden;
    background-color: $color-white;
  }

  &__grid {
    @extend %grid-list__grid;
    grid-template-columns: 10% 10% 5% 15% 15% 10% 15% 10%;

    span:first-child {
      text-align: left;
    }
  }

  &__title {
    @extend %grid-list__title;
    //text-align: left !important;
  }

  &__text {
    font-weight: bold;
  }

  &__forms {
    @extend %grid-list--child-has-animations;

    max-height: 100%;
    overflow-y: auto;
  }
}
