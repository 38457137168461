.admin-forms-page {
  overflow: auto;
  &__modal .modal__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

     & > * {
       margin-bottom: .5rem;
    }
  }

  &__modal-actions {
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    :first-child {
      margin-right: 1rem;
    }
  }
}
