// COLORS
$color-dark-mint: #2bda7e;
$color-greenish-teal: #2dcb7e;
$color-tomato-red: #d71e00;
$color-rusty-red: #bf1d13;
$color-seaweed-green: #37a76e;
$color-seaweed: #15dc7a;
$color-maize: #f9dd4b;
$color-clear-blue: #2e8eec;
$color-butterscotch: #ffb23f;
$color-cornflower: #5654fd;
$color-cornflower-dark: #1c21d3;
$color-coral: #ff4141;
$color-very-light-pink: #e6e6e6;
$color-very-light-blue: #dbe4f9;
$color-light-blue-grey: #b9c6e0;
$color-light-periwinkle: #d1dcf5;
$color-bluey-grey: #8798ad;
$color-white: #ffffff;
$color-clear-blue-20: rgba(46, 91, 255, 0.2);
$color-white-60: rgba(255, 255, 255, 0.6);
$color-ice-blue: #f5f9ff;
$color-ice-blue-two: #eaf2ff;
$color-ice-blue-three: #f2f7ff;
$color-ice-blue-four: #f9fcff;
$color-blue-blue: #1c21d3;

$color-dark-grey-blue: #2e384d;
$color-bluey-grey-two: #aab2c0;
$color-warm-grey: #757575;
$color-silver: #c1c2c4;

$color-greyish-two: #a3a3a3;

$color-black: #333333;
$color-black-two: #202020;
$dark-grey: #888;
$medium-grey: #bbb;
$light-grey: #dddddd;
$lightest-grey: #eeeeee;
$color-greyish: #a4a4a4;
$color-greyish-brown: #555555;
$red: red;
$color-lightish-blue: #5654fd;

$color-purple-gradient: linear-gradient(206deg, #615fe9 79%, #6462f9);

$text-color: $color-black;
$error-color: $red;
$background-color-content: $color-very-light-blue;
$background-color: linear-gradient(to top, #{$color-ice-blue-two}, #{$color-ice-blue-three});

// Screen sizes
$screen-size-half: 'half-screen';

// TYPOGRAPHY
$base-font-family: 'Lato', 'sans-serif';

// SIDEBAR MENU
$sidebar-menu-open-width: 14rem;
$sidebar-menu-closed-width: 4.5rem;

// AUDIO PLAYER
$audio-player-z-index: 1001;

// MODAL
$modal-toggle-time: .5s;
$modal-z-index: 1000;

// AUTH DROPDOWN
$auth-dropdown-z-index: 500;

// MESSAGES DROPDOWN
$message-box-z-index: 40;

// DROPDOWN
$dropdown-z-index: 1002;

// BOX
$padding-box: 1.12rem;

// TOASTS
$toast-container-z-index: 1001;
