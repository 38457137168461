.admin-form-detail {

  &__title {
    margin: .5rem 0 2rem 0;
  }

  &__sub-title {
    margin-top: 1rem;
  }

  &__box-list {
    height: 80%;
  }

  &__question-modal .modal__card-container {
    width: 35%;
    height: 70%;

    @include respond-to($screen-size-half) {
      width: 60%;
    }
  }

  &__part-modal .modal__card-container {
    width: 35%;
    height: 40%;

    @include respond-to($screen-size-half) {
      width: 60%;
    }
  }

  &__modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: .5rem;
    margin-bottom: 2rem;
  }

  &__modal-actions {
    display: flex;
    justify-content: center;

    :first-child {
      margin-right: 1rem;
    }
  }

  &__edit-text {
    margin-right: .5rem;
  }

  &__drag-container {
    background-color: $color-ice-blue-four;
    position: relative;
    user-select: none;

    &--dragging {
      border: 3px solid darken($color-ice-blue-four, 15%) !important;
      background-color: darken($color-ice-blue-four, 10%);
    }

    &:not(:last-child) {
      &:after {
        content: '';
        height: 1px;
        position: absolute;
        bottom: 1px;
        left: 0;
        right: .5rem;
        opacity: .1;
        background-color: $color-black;
      }
    }
  }
}
