.observer {
  margin: 0 !important;
  padding: 1rem;
  border-bottom: 1px solid #eeeeee;
  cursor: default;


  @include box;

  &:hover {
    box-shadow: none;
  }

  &--still:hover {
    transform: none;
    cursor: pointer;
  }

  &__text {
    &--left {
      text-align: left !important;
    }
  }
}
