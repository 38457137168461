.box {
  box-shadow: 0 2px 4px 0 $color-very-light-blue;
  border-radius: .1rem;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &__header {
    border-radius: .1rem;
    font-size: .875rem;
    font-weight: bold;
    color: $color-black;
    padding: $padding-box;
    background-color: $color-white;
    border-bottom: 1px solid rgba($color-black, .1);

    &--active {
      color: $color-clear-blue;
    }
  }

  &__content {

    &--white {
      background-color: $color-white;
    }

    &--grey {
      background-color: $color-ice-blue-four;
    }

    &--empty {
      text-align: center;
    }
  }

  &__empty-text {
    font-size: 1rem;
    color: rgba($color-black, .3);
    display: block;
    padding: 1rem 1.4rem 4rem 1.4rem;
  }
}