.user-list {
  overflow-y: auto;
  @extend %grid-list--child-has-animations;

  &__outside {
    //height: 100%;
  }

  &__grid {
    @extend %grid-list__grid;
    grid-template-columns: 20% 20% 10% 10% 10% 8% 8%;

    @include respond-to('half-screen') {
      grid-template-columns: 20% 20% 10% 10% 10% 8% 8%;
    }
  }

  &__title {
    @extend %grid-list__title;
    text-align: left !important;
    padding-left: 15px;
  }

  &__header {
    background-color: $color-white;
    padding: 1rem 0;
    position: sticky;
    top: 0;
    z-index: 2;
    overflow: hidden;
  }

  &__users {
    height: 70%;
  }
}
