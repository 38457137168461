@mixin icon($color) {
  width: 1rem;
  height: auto;

  stroke: $color;

  path {
    stroke: $color;
  }

  &--filled {
    fill: $color;
  }
}

.icon-grey {
  @include icon($color-light-blue-grey);
}

.icon-white {
  @include icon($color-white);
}

.icon-pointer {
  cursor: pointer;
}