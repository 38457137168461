.styless-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  vertical-align: baseline;
  outline: inherit;
  cursor: pointer;

  &:disabled {
    cursor: inherit;
  }
}