form {
  .form-group {
    margin: 1em 0;
    display: flex;
    justify-content: space-between;
  }

  .form-row {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
 

    .input, .dropdown {
      flex: 1;
      margin-right: 12px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  button {
    cursor: pointer;
  }

  .errors {
    background-color: $error-color;
  }
}
