.settings-page {

    &__content {
        padding: 2rem 8rem 2rem;

        @include respond-to('half-screen') {
            padding: 1.5rem 2rem 2rem 2rem;
        }
    }

    &__section {
        @extend %card;

        background: white;
        padding: .5rem 2rem;
        margin-bottom: 20px;
    }

    &__form {
        background: white;
        margin: 2rem 0px;

    }

    &__form-toggle {

        transition: .3s background-color;
        cursor: pointer !important;
        display: flex;
        align-items: center;
        align-content: center;
    }

    &__form-link {
        cursor: pointer;
        text-decoration: underline;
    }

    .default-input__icon svg {
        fill: $color-very-light-blue;
    }

    &__forms {
        display: flex;
        width: 65%;
        flex-wrap: wrap;

        .form-group {
            width: 45%;

            &:nth-child(odd) {
                margin-right: 5%;
            }
        }
    }
}

