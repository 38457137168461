.date-input-container {
  background-color: $color-white;

  margin-top: 1.5rem;

  .dropdown__title {
    display: none;
  }

  .date-input {
    width: 100%;
    padding: 0.7rem;
    line-height: 1.5;
    position: relative;
    font-size: 0.75rem;
    letter-spacing: .86px;
    background-color: rgba($color-very-light-blue, 0.15);
    border: 1px solid rgba($color-very-light-blue, 0.4);
  }
}