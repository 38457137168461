.item-list {
  height: 100%;

  &__header {
    margin: 1rem 0;
  }

  &__grid {
    @extend %grid-list__grid;
    //grid-template-columns: 8% 18% 20% 15% 16% 5%;
    grid-template-columns: 8% 18% 20% 5% 31% 5%;

    &--small {
      grid-template-columns: 25% 25% 25% 16%;
    }
  }

  &__title {
    @extend %grid-list__title;
  }

  &__text {
    font-weight: bold;
  }

  &__comment {
    // auto ... after 30 characters
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  &__items {
    @extend %grid-list--child-has-animations;

    max-height: 100%;
    overflow-y: auto;
  }

  span:last-child {
    text-align: center;
  }

  &--uploaded {
    span:last-child {
      text-align: right;
    }
  }
}
