.table {
  &__header, &__row {
    span:first-child {
      text-align: left;
    }
  }

  &__header {
    @extend %grid-list__grid;

    border-radius: .1rem;
    font-size: .875rem;
    font-weight: bold;
    color: $color-black;
    padding: 1.12rem;
    background-color: $color-white;
    border-bottom: 1px solid rgba($color-black, .1);

    span {
      @extend %grid-list__title;
    }
  }

  &__row {
    @extend %grid-list__grid;

    font-size: .875rem;
    padding: 1rem 1.12rem;
    background-color: $color-ice-blue-four;
    position: relative;

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        left: 1.12rem;
        right: 1.12rem;
        bottom: 0;
        background-color: $color-black;
        height: 1px;
        opacity: .1;
      }
    }
  }
}