%card {
  box-shadow: 0 2px 4px 0 $color-very-light-blue;
  border-radius: 3px;

  &--light {
    background-color: $color-white;
  }
}

.white-card {
  @extend %card;

  padding: 1.5rem;
  background-color: $color-white;
  font-size: 0.75rem;

  &__title {
    color: $color-black;
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: .5rem;
  }
}