.audits-page {
  height: 100%;

  h2 {
    margin-bottom: 1.5rem;
  }

  &__search {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
  }
}

.flex-container, .auditscontent-filterarea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0px;

  .dropdown {
    width: 50%;
    margin-right: 20px;
   
  //   &:first-child {
  //     margin-right: 30px;
  //   }

    &:last-child {
      margin-right: 0px;
    }
  }

  .date__input {
    width: 200px;
    margin-right: 20px;
  }
}

