.toggle-box {
  width: 100%;

  &__content {
    margin-top: 1rem;
  }

  &--card {
    @extend %card;
    padding: 1.5rem;
    background-color: $color-white;

    display: flex;
    justify-content: space-between;

    label {
      line-height: 1.5rem;
    }
  }
}