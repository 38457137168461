@import 'flex';
@import 'space';

.cursor-forbidden {
  cursor: not-allowed;
}


.textarea-resize-vertical {
  resize: vertical;
}

.mb-1 {
  margin-bottom: 1rem;
}

.flex {
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.white {
  background-color: white;
}

.inline-block {
  display: inline-block;
}

.normal-checkbox {
  .checkbox {
    &__label {
      display: inline;
    }
  }
}

.scrollable {
  overflow: auto;
}

.mb-1 {
  margin-bottom: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}
