.auth-dropdown {
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  min-width: 16rem;
  position: relative;
  cursor: pointer;
  z-index: $auth-dropdown-z-index;

  &--light {
    background-color: $color-white;
    color: $color-black;
  }

  &--dark &__menu {
    background-color: $color-black;
    border: none; // change to border color
  }

  &--dark &__action {
    &:hover {
      background-color: lighten($color-black, 15%);
    }
  }

  &--dark {
    background-color: $color-black;
    color: $color-white;
  }

  &__img {
    height: 2.5rem;
    border-radius: 50%;
    margin-right: .5rem;
  }

  &__user {
    font-weight: bold;

    &--main {
      display: block;
      font-size: .9rem;
      opacity: .9;
    }

    &--sub {
      display: block;
      font-size: .7rem;
      opacity: .3;
    }
  }

  &__toggle-button {
    flex: 1;
    text-align: right;

    svg {
      width: 1rem;
      height: 1rem;
      transform: rotateY(180deg);
    }
  }

  &__menu {
    position: absolute;
    left: -1px;
    right: 0;
    top: 100%;
    width: 100%;
    transition: height .5s;
    animation-timing-function: ease-in-out;
    transform-origin: left top;
    background-color: $color-white;
    box-shadow: 2px 2px 4px 0 #cfdeff;
    overflow: hidden;

    &--open {
      border: 1px solid $color-ice-blue;
    }

    &--closed {
      display: none;
    }

    &-container {
      transition: left .5s;
      width: 100%;
    }

    &--left &-container {
      left: -100%;
    }
  }

  &__icon {
    height: 1.3rem;
    width: 1.3rem;

    &--offset-right {
      margin-right: 2rem;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    transition: transform .5s;

    &:hover {
      background-color: lighten($color-ice-blue, .5%);
    }

    &:not(:last-of-type) {
      border-bottom: 2px solid $color-ice-blue;
    }
  }

  &__profile {
    position: absolute;
    top: 100%;
    left: 100%;
    width: 100%;
    height: calc(100vh - 100%);
    padding: 0 2rem 1rem 2rem;
    background-color: $color-white;
    box-shadow: 2px 2px 4px 0 #cfdeff;
    transition: left .5s;
    cursor: default;
    overflow-y: scroll;

    span {
      font-weight: bold;
      color: $color-bluey-grey-two;
    }

    &--open {
      left: 0;
    }

    &--hidden {
      display: none;
    }
  }

  &__profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;

    img {
      width: 7rem;
      height: auto;
      border-radius: 50%;
      margin-bottom: 1.5rem;
    }

    span:first-of-type {
      color: $color-dark-grey-blue;
      font-size: 1.5rem;
      margin-bottom: .5rem;
    }

    span:last-of-type {
      font-size: .8rem;
      text-transform: uppercase;
    }
  }

  &__profile-info {
    margin-bottom: 3rem;

    p:last-of-type {
      margin-bottom: 1rem;
    }
  }

  &__info-block {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__info-title {
    color: $color-bluey-grey-two;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: bold;
    margin-bottom: .4rem;
  }

  &__info-text {
    color: $color-black;
  }

  &__profile-footer {
    p {
      margin-bottom: 1.5rem;
    }
  }
}
