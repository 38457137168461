.center-list {
  height: 100%;
  overflow-y: auto;
  @extend %grid-list--child-has-animations;

  &--light {}
  &--dark {}

  &--big &__grid {
    grid-template-columns: 20% 10% 20% 12% 10% 13% 2%;
  }

  &__header {
    background: $color-white;
    padding: 1rem 0
 }

  &__grid {
    @extend %grid-list__grid;
    padding: 1rem;
    grid-template-columns: 20% 10% 20% 19% 10% 10%;
  }

  &__title {
    @extend %grid-list__title;
    text-align: left !important;
  }

  &__centers {
    max-height: 73%;
  }
}
