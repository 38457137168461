.upload-area {
  padding: .7rem;

  background-color: rgba($color-very-light-blue, .15);
  border: dashed 3px rgba($color-very-light-blue, .4);
  border-radius: .31rem;
  text-align: center;
  transition: background-color .2s;
  position: relative;

  &--dark {
    background-color: rgba($color-very-light-blue, .5);
  }

  * {
    pointer-events: none;
  }

  &__icon-container {
    padding: .8rem 0 ;
  }

  &__icon {
    height: 1.3rem;
    fill: $color-light-blue-grey;
  }

  &__text {
    font-size: .875rem;
    color: rgba($color-black, .8);
    user-select: none;

    &--warning {
      color: red;
      font-weight: bold;
    }

    &--upload {
      font-weight: bold;
      color: rgba($color-cornflower, .8);
      pointer-events: auto;
    }

    &--pointer {
      cursor: pointer;
    }

    &--default {
      cursor: default;
    }
  }

  &__upload-bar-container {
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: .31rem;
  }

  &__upload-bar {
    height: 100%;
    transition: width .2s;
    border-radius: .31rem;

    &--blue {
      background-color: $color-cornflower;
    }

    &--green {
      background-color: $color-dark-mint;
    }
  }

  &__check-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: .9;
    }

    svg {
      width: 2rem;
      height: 2rem;

      path {
        stroke: $color-white;
      }
    }
  }

  &__delete-icon {
    position: absolute;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    pointer-events: all;
    fill: $color-white;

    &:hover {
      cursor: pointer;
    }

  }

  &__filename {
    color: $color-white;
    font-weight: bold;
    margin-right: 1rem;
    width: 50%;
  }
}
