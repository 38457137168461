.user-item {
  grid-template-columns: 20% 20% 10% 10% 10% 8% 8%;
  background-color: $color-white;
  margin: 0;

  @include box;

  @include respond-to('half-screen') {
    font-size: .6rem;
    grid-template-columns: 20% 20% 15% 10% 10% 8% 8%;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .user__detail {
      text-align: left;
      padding-left: 15px !important;

      &--link {
          cursor: pointer;
          margin-bottom: .5rem;
          padding-left: 10px;
          color: $color-clear-blue;

        &:hover {
          text-decoration: underline;
        }
      }
  }
}
