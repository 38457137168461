.audit-create-box {
  height: 100%;

  &__errors {
    padding: 1rem;
    color: $color-tomato-red;
  }

  &__modal {
    .modal__card-container {
      height: 75%;
      width: 70%;
    }
  }

  &__calls-tab {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: .5rem;
    height: 93%;
  }

  &__calls-container {
    padding-top: 1rem;
  }

  &__title {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  &__controls {
    position: absolute;
    display: flex;
    right: 2rem;
    bottom: 1rem;

    :first-child {
      margin-right: 1.5rem;
    }
  }

  &__select {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 2rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
