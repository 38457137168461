.button {
  padding: .7rem 2rem;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color .3s;
  outline: none;
  border: none;
  font-weight: 300;

  &--bold {
    font-weight: bold;
  }

  &--inline {
    width: auto;
  }

  &--right {
    float: right;
  }

  &-cancel {
    &--light {
      background-color: $color-white;
      border: 1px solid $color-light-blue-grey;
      color: $color-light-blue-grey;

      &:hover {
        background-color: $color-light-blue-grey;
        color: $color-white
      }
    }

    &--dark {
      &:hover {

      }
    }
  }

  &-basic {
    &--light {
      color: $color-white;
      background-color: $color-cornflower;

      &:hover {
        background-color: $color-cornflower-dark;
      }
    }

    &--disabled {
      opacity: .2;
      cursor: not-allowed;

      &:hover {
        background-color: $color-cornflower;
      }
    }
  }

  &-delete {
    &--light {
      color: $color-white;
      background-color: $color-coral;
      font-weight: 300;

      &:hover {
        background-color: darken($color-coral, 20%);
      }
    }
  }

  &-back {
    display: flex;
    align-items: center;
    padding: .8rem 0;
    // border: 1px solid $color-light-blue-grey;
    font-weight: normal;

    &--light {
      color: $color-bluey-grey;
      background-color: transparent;
    }

    &--dark {

    }

    &__icon {
      width: 1em;
      margin-right: 1em;
    }

    &__text {
      transition: transform .4s;
    }

    &:hover &__text {
      transform: translateX(.5rem);
    }
  }
}