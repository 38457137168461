.upload-item {
  padding: .5rem 0;
  display: flex;

  &:not(:last-child) {
    margin-bottom: .5rem;
  }

  &--right {
    flex: 1;
  }

  &__icon {
    width: 1rem;
    margin-right: 1.5rem;
  }

  &--audio &__icon {
    fill: $color-cornflower;
  }

  &--pdf &__icon {
    stroke: $color-cornflower;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
  }

  &__name {
    font-size: .875rem;
  }

  &__delete {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }

  &__progress-bar {
    width: 100%;
    height: .25rem;
    background-color: $color-very-light-pink;
    border-radius: 2px;
  }

  &__progress-indicator {
    height: 100%;
    border-radius: 2px;
    transition: width .2s;
  }

  &--audio &__progress-indicator {
    background-color: $color-cornflower;
  }

  &--pdf &__progress-indicator {
    background-color: $color-dark-mint;
  }


}