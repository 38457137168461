.form-tab {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: .875rem;
  color: $color-black;
  line-height: 1.5;
  margin: 1rem 0;
  cursor: pointer;

  &--light {}
  &--dark {}

  &--disabled {
    cursor: not-allowed;
  }

  &:not(:last-of-type) {
    &:after {
      content: '';
      height: 2px;
      border-radius: 1px;
      flex: 1;
      margin: 0 1rem;
      background-color: $color-black;
      opacity: .2;
    }
  }

  &__number {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .75rem;
    opacity: .2;

    border-radius: 20rem;
    border: 2px solid $color-black;
    margin-right: 1rem;
  }

  &__name {
    opacity: .2;
  }

  &--active &__name {
    opacity: 1;
  }

  &--active &__number {
    opacity: 1;
    background-color: $color-clear-blue;
    border: none;
    color: $color-white;
  }

  &--done &__number {
    border-color: $color-seaweed-green;
    opacity: 1;
  }
}
