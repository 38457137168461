.statistics-page {
  height: 100%;
  padding: 1.5rem 8rem 1rem 8rem;
  overflow-y: auto;

  &__title {
    margin-top: 3rem;
  }

  &__form-filter {
    width: 15%;
    margin: 1.5rem 0;
  }

  &__content {
    font-size: .75rem;
  }

  &__line {
    padding: 0.8em;
  }

  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  &__col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    &--left {
      flex-basis: 33.33%;
    }

    &--right {
      flex-basis: 66.66%;
    }
  }

  &__doughnut {
    &--wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--canvas {
      height: 30% !important;
    }
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__filters {
    padding: 0.8rem;
  }

  &__summarized {
    min-width: 20%;
    margin-left: 30px;

    &--title {
      font-weight: bold;
      margin-bottom: .5rem;

    }
  }

  &__loader-container {
    position: relative;

    &--bright {
      opacity: .5;
    }
  }
}
