.account-page {
  height: 100%;

  &__actions {
    margin-top: 2rem;
    display: flex;
  }

  &__card {
    flex: 1;
    max-width: 50%;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &__text {
      color: $color-clear-blue;
      letter-spacing: 0.86px;
      cursor: pointer;
    }
  }

  &__password-modal {
    .modal__card-container {
      width: 30%;
      height: 65%;
    }
  }
}