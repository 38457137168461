.dev-tool {
  padding: 2rem;

  &__section {
    margin: 2rem 0;
  }

  &__user {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__users {

  }

  &__log-entry {
    &--error {
      color: $color-tomato-red;
    }

    &--info {
      color: $color-seaweed-green;
    }
  }

  &__log {
    margin-top: 1rem;
    border-radius: 3px;
    background-color: rgba($color-very-light-blue, .15);
    border: 1px solid rgba($color-very-light-blue, .4);
    padding: .8rem .5rem;
    height: 20rem;
    overflow: auto;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__entry-title {
    margin-right: .5rem;
  }
}
