.form-page {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
        margin-bottom: 1rem;

        & > div:first-child {
            margin-bottom: .5rem;
        }

        h1 {
            margin: 0;
        }
    }

    &__content {
        overflow-y: scroll;
    }

    .box:first-child, .box:last-child {
        .form-question__explanation-box {
            &:after {
                height: 2.5rem;
            }
        }
    }

    .box:first-child {
        .form-question__explanation-box {
            top: 50%;
            transform: translateY(0);

            &:after {
                top: 0;
                transform: translateX(-90%);
                clip-path: polygon(0 0, 100% 0, 100% 100%);
            }
        }
    }

    .box:last-child {
        .form-question__explanation-box {
            top: 0;
            transform: translateY(-90%);

            &:after {
                top: 100%;
                transform: translateY(-100%) translateX(-80%);
                clip-path: polygon(0 100%, 100% 100%, 100% 0);
            }
        }
    }

    .form-question__image {
        margin-bottom: 1rem;
    }
}
