.content {
  transition: margin .5s;
  height: 92%;

  &--big {
    margin-left: $sidebar-menu-open-width;
  }

  &--small {
    margin-left: $sidebar-menu-closed-width;
  }
}
