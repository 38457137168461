.report-feedback-page {
    padding: 2rem 8rem 2rem;

    @include respond-to('half-screen') {
        padding: 1.5rem 2rem 2rem 2rem;
    }

    textarea {
        min-height: 10rem;
    }

    input, textarea {
        margin: 1rem 0;
    }
}