.image-upload {
  margin-top: 1rem;
  margin-bottom: 2rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
}

