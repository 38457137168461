@mixin box {
  @extend %card;

  margin: 1rem 0;
  padding: 1rem  0;
  align-items: center;
  font-size: 13px;
  transition: all .5s;
  cursor: pointer;
  position: relative;

  &:hover {
    transform: translateX(-1rem);
    box-shadow: 0 .6rem 1.5rem 0 $color-very-light-blue;
  }

  &--light {
    @extend %card--light;
  }

  &--light &__text {
    &--grey {
      color: $color-warm-grey;
    }
  }

  &--dark {}

  &__status {
    border-radius: 5px;
    padding: .8rem;
    color: $color-white;
    letter-spacing: 0.86px;
    text-align: center;
    font-size: .7rem;

    @include respond-to('half-screen') {
      padding: .5rem;
      font-size: .5rem;
    }

    &--rejected {
      background-color: $color-coral;
    }

    &--approved {
      background-color: $color-greenish-teal;
    }

    &--denied {
      background-color: $color-coral;
    }

    &--todo {
      border: 1px solid $color-silver;
      color: $color-black;
    }

    &--small {
      display: flex;
      align-self: center;
      padding: .5rem;
      font-size: .7rem;
    }

    &--done {
      background-color: $color-clear-blue;
    }
  }

  &__icon {
    width: .8rem;
    height: .8rem;
    transform: rotateY(180deg);
  }
}
