.audit-list {
  @extend %grid-list--child-has-animations;
  overflow-y: auto;

  &--light {}
  &--dark {}

  &__header {
    position: sticky;
    top: 0;
    z-index: 2;
    overflow: hidden;

    background-color: $color-white;
    padding: 1rem 0;
 }

 &__released-button {
    border-radius: 5px;
    padding: .8rem;
    color: $color-white;
    font-weight: bold;
    letter-spacing: 0.86px;
    text-align: center;
    font-size: .7rem;

    @include respond-to('half-screen') {
      padding: .5rem;
      font-size: .5rem;
    }

    &--green {
      background-color: $color-cornflower;

      &:hover {
        background-color: $color-cornflower-dark;
      }
    }

    &--red {
      background-color: $color-tomato-red;

      &:hover {
        background-color: $color-rusty-red;
      }
    }
 }

  &__grid {
    @extend %grid-list__grid;
    grid-template-columns: 10% 10% 20% 19% 10% 18% 3%;

    @include respond-to('half-screen') {
      grid-template-columns: 5% 15% 12% 20% 10% 19% 3%;
    }

    &--with-auditor {
      @extend %grid-list__grid;
      grid-template-columns: 10% 10% 11% 13% 13% 15% 13% 3%;

      @include respond-to('half-screen') {
        grid-template-columns: 5% 10% 11% 13% 13% 15% 13% 3%;
      }
    }

    &--with-auditor {
      @extend %grid-list__grid;
      grid-template-columns: 10% 10% 10% 11% 13% 10% 13% 7% 3%;

      @include respond-to('half-screen') {
        grid-template-columns: 10% 10% 10% 11% 13% 10% 13% 7% 3%;
      }
    }

    &--stats {
      @extend %grid-list__grid;
      grid-template-columns: 5% 10% 18% 15% 12% 10% 10% 3%;

      @include respond-to('half-screen') {
        grid-template-columns: 5% 14% 18% 15% 8% 10% 5% 5%;
      }
    }
  }

  &__title {
    @extend %grid-list__title;
  }

  &__audits {
    @extend %grid-list--child-has-animations;

    max-height: 73%;

  }
}
