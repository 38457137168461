.account-card {
  @extend %card;

  background-color: $color-white;
  padding: 1.5rem;

  &__section {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      margin-top: 2rem;
    }
  }

  &__title {
    font-size: 1.125rem;
    color: $color-black;
    font-weight: bold;
    margin-bottom: .5rem;
  }

  &__sub-title {
    font-size: 0.725rem;
    color: $color-black;
    line-height: 1.5;
  }

  &__text {
    font-size: 0.725rem;
    color: $color-black;
    letter-spacing: 0.86px;

    &--title {
      margin-bottom: 1rem;
    }

    &--spaced {
      margin-right: 3rem;
    }
  }

  &__avatar {
    margin-right: 1rem;

    .avatar__image {
      width: 5rem;
      height: 5rem;
    }
  }

  &__button {
    border-radius: 3px;
    border: 1px solid $color-blue-blue;
    color: $color-blue-blue;
    font-size: .875rem;
    padding: .7rem 1rem;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
      color: $color-white;
      background-color: $color-blue-blue;
    }
  }

  &__input-grid {
    display: grid;
    grid-template-columns: calc(33% - 1.5rem) calc(33% - 1.5rem) calc(33% - 1.5rem);
    grid-column-gap: 1.5rem;
  }
}