.part-modal {

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    :first-child {
      margin-right: 1rem;
    }
  }
}
