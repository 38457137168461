.comments-box {
  width: 100%;
  margin-bottom: 1rem;

  &__content {
    margin-top: 1rem;
  }

  &--card {
    @extend %card;
    padding: 2rem 1.5rem;
    background-color: $color-white;
  }

}
